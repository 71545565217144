<template>
    <div class="basic_data_edit">
        <div class="data_content data-content-form scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form class="demo-form-inline form_row" ref="formdata" :inline="true" :model="formdata.saveJson" size="small" label-width="200px" :rules="rules">
                <el-form-item label="报告名称" prop="BGMC">
                    <el-input v-model="formdata.saveJson.BGMC" placeholder="请输入报告名称" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="报告类型" prop="BGLX">
                    <el-select v-model="formdata.saveJson.BGLX" placeholder="请选择报告类型" filterable :disabled="!isEdit">
                        <el-option v-for="(item,index) in bglxList" :key="index" :label="item.Name" :value="item.Code"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="对象类型" prop="DXLX">
                    <el-select v-model="formdata.saveJson.DXLX" filterable placeholder="请选择对象类型" :disabled="!isEdit" @change="handleDxlxChanged">
                        <el-option v-for="item in dxlxOtions" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="对象" prop="PGDXID" class="must-fill">
                    <el-cascader v-model="dxArrValue" style="width: 100%" :options="dxOtions" :props="props" clearable filterable placeholder="请选择对象" :disabled="!isEdit || !formdata.saveJson.DXLX" @change="handleDxChanged"></el-cascader>
                </el-form-item> -->
                <el-form-item label="负责人" prop="FZRXM">
                    <el-input v-model="formdata.saveJson.FZRXM" placeholder="请输入负责人" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="报告生成时间" prop="CJSJ">
                    <el-date-picker type="date" placeholder="请选择报告生成时间" v-model="formdata.saveJson.CJSJ" style="width: 100%;" value-format="yyyy-MM-dd" :disabled="!isEdit"></el-date-picker>
                </el-form-item>
                <el-form-item label="内容概要" prop="NRJY">
                    <el-input type="textarea" :rows="6" v-model="formdata.saveJson.NRJY" placeholder="请输入内容概要" :maxlength="1000" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                </el-form-item>

                <div>
                    <el-form-item class="must-fill" label="报告上传">
                        <div v-if="fileList && fileList.length>0 && !isEdit">
                            <div v-for="(item,index) in fileList" :key="index">
                                <a class="assess-a" :href="item.url" target="_blank">{{item.name}}</a>
                            </div>
                        </div>
                        <div class="no-file" v-if="!fileList || fileList.length===0 && !isEdit">未上传</div>
                        <FileUpload v-if="isEdit" @successUpload="successUpload" @handleDelFile="handleDelFile" :fileList="fileList" fileIndex="1" :fileSum="1"></FileUpload>
                    </el-form-item>
                </div>
                <!-- <div>
                    <el-form-item label="报告数据上传">
                        <div v-if="fileList2 && fileList2.length>0 && !isEdit">
                            <div v-for="(item,index) in fileList2" :key="index">
                                <a class="assess-a" :href="item.url" target="_blank">{{item.name}}</a>
                            </div>
                        </div>
                        <div class="no-file" v-if="!fileList2 || fileList2.length===0 && !isEdit">未上传</div>
                        <FileUpload v-if="isEdit" @successUpload="successUpload" @handleDelFile="handleDelFile" :fileList="fileList2" fileIndex="2" :fileSum="5"></FileUpload>
                    </el-form-item>
                </div> -->

            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import FileUpload from "./file-upload"
export default {
    name: "report_manage_form",
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            formdata: {
                saveJson: {
                    BGMC: "",
                    BGLX: "",
                    DXLX: "",
                    NRJY: "",
                    FZRXM: "",
                    CJSJ: "",
                    FILE: "",
                    DATAFILE: '',
                    PGDXID: ''
                },
                heritageId: "",
                userName: "",
                userId: ""
            },
            bglxList: [], // 报告类型
            dxlxOtions: [],
            dxArrValue: [],
            props: {
                multiple: true,
                value: 'ID',
                label: 'PGDXMC',
                children: 'CHILDREN'
            },
            dxOtions: [],

            rules: {
                BGMC: [
                    { required: true, message: '请输入报告名称', trigger: 'blur' },
                ],
                BGLX: [
                    { required: true, message: '请选择报告类型', trigger: 'change' }
                ],
                DXLX: [
                    { required: true, message: '请选择对象类型', trigger: 'change' }
                ],
                NRJY: [
                    { required: true, message: '请输入内容概要', trigger: 'blur' },
                ],
            },
            fileList: [],
            fileList2: [],
            delFileIds: '',
            delFileIdsArr: [],
        };
    },
    components: {
        FileUpload
    },
    watch: {
    },
    mounted() {
        this.snButton.buttonData[1].isShow = false;
        this.getBglxList();
        this.getDxlxList();
        // this.getDxList();
        this.delFileIds = '';
        this.delFileIdsArr = [];
    },
    methods: {
        ...mapActions([
            'SaveReport', //新建/修改报告
            "GetEnumItem", //获取枚举列表
            "GetReportDetails", // 获取报告详情
            "DelFile", // 删除附件
            "GetReportPgdxEnum", // 获取对象列表
        ]),
        // 获取报告类型列表
        async getBglxList() {
            this.bglxList = [];
            let result = await this.GetEnumItem({
                Enumid: 'cf24c70d-94c3-11ea-bd8d-000c2977b7fd'
            });
            this.bglxList = result;
        },
        // 获取对象类型列表
        async getDxlxList() {
            this.dxlxOtions = [];
            let result = await this.GetEnumItem({
                Enumid: '63804b1c-c259-11ea-bd8d-000c2977b7fd'
            });
            this.dxlxOtions = result;
        },
        // 获取对象列表
        async getDxList(val) {
            this.dxOtions = [];
            let dxlx = val;
            let result = await this.GetReportPgdxEnum({
                dxlx: dxlx
            });
            this.dxOtions = result;
        },
        async getFromData() {
            this.dxArrValue = [];
            this.fileList = [];
            this.fileList2 = [];
            let bgParam = JSON.parse(this.$route.query.data);
            let result = await this.GetReportDetails({
                bgid: bgParam.ID
            })
            if (result && result.length>0) {
                Object.assign(this.formdata.saveJson, result[0]);
            }
            let fjIdsArr = [];
            let fjIdsArr2 = [];
            if (this.formdata.saveJson && this.formdata.saveJson.ID) {
                if(this.formdata.saveJson.DXLX) {
                    this.getDxList(this.formdata.saveJson.DXLX);
                    if(this.formdata.saveJson.PGDXID) {
                        if(this.formdata.saveJson.DXLX==='2') {
                            let ycysArr = this.formdata.saveJson.YCYSBM.split(",");
                            let qyArr = this.formdata.saveJson.PGDXID.split(",");
                            for (let i = 0; i < ycysArr.length; i++) {
                                for (let j = 0; j < qyArr.length; j++) {
                                    if(i===j) {
                                        let arr = [ycysArr[i],qyArr[j]];
                                        this.dxArrValue.push(arr);
                                    }
                                }
                            }
                        } else {
                            this.dxArrValue = this.formdata.saveJson.PGDXID.split(",");
                        }
                    }
                }
                
                if(this.formdata.saveJson.FILE){
                    this.fileList.push({
                        Guid: this.formdata.saveJson.FILE.ID,
                        name: this.formdata.saveJson.FILE.WDMC,
                        url: this.formdata.saveJson.FILE.CCLJ,
                    })
                    fjIdsArr.push(this.formdata.saveJson.FILE.ID);
                }
                this.formdata.saveJson.FILE = fjIdsArr.toString();

                if(this.formdata.saveJson.DATAFILE && this.formdata.saveJson.DATAFILE.length>0){
                    this.formdata.saveJson.DATAFILE.forEach(item=>{
                        this.fileList2.push({
                            Guid: item.ID,
                            name: item.WDMC,
                            url: item.CCLJ,
                        })
                        fjIdsArr2.push(item.ID);
                    })
                }
                this.formdata.saveJson.DATAFILE = fjIdsArr2.toString();
            }
        },
        successUpload(val, fileIndex) {
            if (fileIndex === '1') {
                this.formdata.saveJson.FILE = val;
            } else if (fileIndex === '2') {
                this.formdata.saveJson.DATAFILE = val;
            }
        },
        handleDelFile(val, fileIndex) {
            let canDelFileList = [];
            if (fileIndex === '1') {
                canDelFileList = this.fileList;
                this.formdata.saveJson.FILE = '';
            } else if (fileIndex === '2') {
                canDelFileList = this.fileList2;
            }

            if (canDelFileList && canDelFileList.length > 0) {
                canDelFileList.forEach(item => {
                    if (item.Guid === val) {
                        this.delFileIdsArr.push(val);
                    }
                })
                this.delFileIds = this.delFileIdsArr.join(',');
            }
        },
        async delFile(ids) {
            let result = await this.DelFile({
                ids: ids
            });
            if (result.ISSUCCESS) {
                // this.$message({
                //     type: 'success',
                //     message: '移除成功'
                // });
                this.saveReport();
            } else {
                this.$message({
                    type: 'error',
                    message: '移除失败'
                });
            }
        },
        handleDxlxChanged(val) {
            this.dxArrValue = [];
            this.formdata.saveJson.PGDXID = "";
            if(val) {
                this.getDxList(val);
            }
        },
        handleDxChanged(val){
        },
        saveData() {
            this.formdata.saveJson.PGDXID = "";
            if(this.formdata.saveJson.DXLX==='2') {
                this.formdata.saveJson.YCYSBM = '';
            }
            if(this.dxArrValue && this.dxArrValue.length>0){
                if(this.formdata.saveJson.DXLX==='2'){
                    let ycysArr = [];
                    let qyArr = [];
                    this.dxArrValue.forEach((item)=>{
                        ycysArr.push(item[0]);
                        qyArr.push(item[1]);
                    })
                    this.formdata.saveJson.YCYSBM = ycysArr.toString();
                    this.formdata.saveJson.PGDXID = qyArr.toString();
                }else{
                    this.formdata.saveJson.PGDXID = this.dxArrValue.toString();
                }
            }

            // if(!this.formdata.saveJson.PGDXID) {
            //     this.$message({
            //         type: 'warning',
            //         message: '请选择对象1'
            //     });
            // } 
            
            if(!this.formdata.saveJson.FILE){
                this.$message({
                    type: 'warning',
                    message: '请上传报告'
                });
            }else{
                if(this.delFileIds){
                    this.delFile(this.delFileIds);
                }else{
                    this.saveReport();
                }
            }
        },
        async saveReport(){
            let res = await this.SaveReport(this.formdata.saveJson);
            if (res.ISSUCCESS) {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.returnList();
            } else {
                this.$message({
                    type: 'error',
                    message: '保存失败'
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.data-content-form {
    width: 100%;
    height: calc(100% - 150px)!important;
    .no-file{
        color: #c0c4cc;
    }
    .assess-a {
        color: #00f;
        cursor: pointer;
    }
}
</style>